import "ant-design-vue/es/drawer/style";
import _Drawer from "ant-design-vue/es/drawer";
import "ant-design-vue/es/config-provider/style";
import _ConfigProvider from "ant-design-vue/es/config-provider";
import "ant-design-vue/es/spin/style";
import _Spin from "ant-design-vue/es/spin";
import { defineComponent, defineAsyncComponent, h, ref, onMounted, onBeforeUnmount, nextTick, render, useAttrs, markRaw, createVNode } from 'vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
export let appContext = null;
export const useWindowsProvider = app => appContext = app;
export const useDrawer = () => {
  if (appContext === null) throw new Error('使用useDrawer前, 请先useWindowsProvider初始化来获取实例上下');
  let _setupComponent = h('div', {
    style: {
      'width': '100%',
      'height': '100%',
      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'center'
    }
  }, h(_Spin, {
    tip: '加载中'
  }));
  const _component = defineComponent({
    setup() {
      const attrs = useAttrs();
      const visible = ref(false);
      const closeHookDrawer = detail => {
        window.dispatchEvent(new CustomEvent(attrs.props.drawer_event_id, {
          detail
        }));
        nextTick(() => visible.value = false);
      };
      onMounted(() => {
        nextTick(() => {
          console.log('attrs', attrs);
          visible.value = true;
        });
      });
      return () => h(_ConfigProvider, {
        locale: zhCN
      }, () => {
        var _attrs$props, _attrs$props2, _attrs$props3;
        return h(_Drawer, {
          visible: visible.value,
          // 控制对话框打开状态
          'onUpdate:visible': val => visible.value = val,
          // 更新打开状态的回调
          maskClosable: false,
          ...attrs.props
        }, {
          default: () => h(_setupComponent, {
            ...attrs.componentProps,
            '_parentHookId': attrs.props.drawer_event_id,
            closeHookDrawer
          }),
          ...(((_attrs$props = attrs.props) === null || _attrs$props === void 0 ? void 0 : _attrs$props.extraSlot) === true ? {
            extra: () => h('div', {
              id: `${attrs.props.drawer_event_id}__extra`
            })
          } : {}),
          ...(((_attrs$props2 = attrs.props) === null || _attrs$props2 === void 0 ? void 0 : _attrs$props2.titleSlot) === true ? {
            title: () => h('div', {
              id: `${attrs.props.drawer_event_id}__title`
            })
          } : {}),
          ...(((_attrs$props3 = attrs.props) === null || _attrs$props3 === void 0 ? void 0 : _attrs$props3.footerSlot) === true ? {
            footer: () => h('div', {
              id: `${attrs.props.drawer_event_id}__footer`
            })
          } : {})
        });
      });
    }
  });
  const openDrawer = (options = {
    componentProps: {}
  }) => {
    const {
      componentProps,
      component = markRaw({}),
      ...props
    } = options;
    const container = document.createElement('div');
    const eventId = 'drawer_event_' + Date.now();
    const callback = event => {
      props.onClose && props.onClose(event.detail);
    };
    const vnode = createVNode(_component, {
      props: Object.assign(props, {
        drawer_event_id: eventId,
        onAfterOpenChange: open => {
          if (open === false) {
            nextTick(() => {
              // console.log('移除动态调用容器')
              window.removeEventListener(eventId, callback);
              render(null, container);
              document.body.removeChild(container);
            });
          }
        }
      }),
      componentProps
    });
    vnode.appContext = appContext._context;
    window.addEventListener(eventId, callback);
    document.body.appendChild(container);
    render(vnode, container);
    if (Object.prototype.toString.call(component) === '[object Function]') {
      nextTick(() => _setupComponent = defineAsyncComponent(component));
    } else {
      nextTick(() => _setupComponent = component);
    }
  };
  return {
    openDrawer
  };
};